import React from 'react';
import tw from 'twin.macro';
import { Layout } from '../components'
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import Logo from "../images/staynavi_logo.png";
import '../styles/global.css';

const styles = {
  // Move long tw sets out of jsx to keep it scannable
  container: ({ hasBackground }) => [
    tw`flex flex-col items-center justify-center h-screen` &&
    hasBackground && tw`bg-background`,
  ],
}

const IndexPage = () => {

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {

    const singleCouponDates = [
      "2022/6/18",
      "2022/6/25",
      "2022/7/2",
      "2022/7/9",
      "2022/7/16",
      "2022/7/17",
      "2022/7/23",
      "2022/7/30",
      "2022/8/6",
      "2022/8/13",
      "2022/8/20",
      "2022/8/27",
      "2022/9/3",
      "2022/9/10",
      "2022/9/17",
      "2022/9/18",
      "2022/9/23",
      "2022/9/24",
      "2022/10/1",
      "2022/10/8",
      "2022/10/9",
      "2022/10/15",
      "2022/10/22",
      "2022/10/29",
      "2022/11/5",
      "2022/11/12",
      "2022/11/19",
      "2022/11/26",
      "2022/12/3",
      "2022/12/10",
      "2022/12/17",
      "2022/12/24",
      "2022/12/31"
    ]

    // 人数
    const peopleCount = Number(data.peopleCount ?? 0);

    // チェックイン日
    const checkIn = data.checkIn;

    // チェックアウト日
    const checkOut = data.checkOut;

    // 宿泊代金総額
    let value = data.value;
    const isValueLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(value);

    if (isValueLocaleString) {
      value = value?.replace(/,/g, '');
    }

    // 高速道路周遊バス
    let bus = (data.bus ?? 0);
    const isBusLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(bus);

    if (isBusLocaleString) {
      bus = bus?.replace(/,/g, '');
    }

    // レンタカー
    let car = data.car ?? 0;
    const isCarLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(car);

    if (isCarLocaleString) {
      car = car?.replace(/,/g, '');
    }

    // JAL
    let jal = data.jal ?? 0;
    const isJALLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(jal);

    if (isJALLocaleString) {
      jal = jal?.replace(/,/g, '');
    }

    // JR
    let jr = data.jr ?? 0;
    const isJRLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(jr);

    if (isJRLocaleString) {
      jr = jr?.replace(/,/g, '');
    }

    // JAL
    let jrEx = data.jrEx ?? 0;
    const isJRExLLocaleString = /\b\d{1,3}(,\d{3})*\b/.test(jrEx);

    if (isJRExLLocaleString) {
      jrEx = jrEx?.replace(/,/g, '');
    }

    if (!/^[-]?(\d+)[.]?(\d+)?$/.test(value)) {
      setError('value', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (value <= 0) {
      setError('value', {
        type: 'manual',
        message: '宿泊代金総額は0円以上で入力してください',
      });
      return;
    } else if (bus && !/^[-]?(\d+)[.]?(\d+)?$/.test(bus)) {
      setError('bus', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (car && !/^[-]?(\d+)[.]?(\d+)?$/.test(car)) {
      setError('car', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (jal && !/^[-]?(\d+)[.]?(\d+)?$/.test(jal)) {
      setError('jal', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (jr && !/^[-]?(\d+)[.]?(\d+)?$/.test(jr)) {
      setError('jr', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (jrEx && !/^[-]?(\d+)[.]?(\d+)?$/.test(jrEx)) {
      setError('jrEx', {
        type: 'manual',
        message: '数値で入力してください',
      });
      return;
    } else if (checkIn > checkOut) {
      setError('checkIn', {
        type: 'manual',
        message: 'チェックイン日がチェックアウト日を超えています',
      });
      return;
    }

    value = Number(value ?? 0);

    // 割引率
    const discountRate = 0.4;

    // 地域限定クーポン単価
    const regionalCommonCouponUnitPrice = 1000;

    // 地域限定クーポン枚数
    const regionalCommonCouponCount = 3;


    let maxDate = new Date(checkOut) // 日付範囲の上限
    let minDate = new Date(checkIn) // 日付範囲の下限

    var y = minDate.getFullYear();
    var m = ("00" + (minDate.getMonth() + 1)).slice(-2);
    var d = ("00" + minDate.getDate()).slice(-2);
    var result = y + "/" + m + "/" + d;

    minDate = new Date(result);

    var y = maxDate.getFullYear();
    var m = ("00" + (maxDate.getMonth() + 1)).slice(-2);
    var d = ("00" + maxDate.getDate()).slice(-2);
    var result2 = y + "/" + m + "/" + d;

    maxDate = new Date(result2);

    // 泊数
    const restCount = (maxDate - minDate) / 86400000;
    const filterDates = singleCouponDates.filter(singleCouponDate => {

      const targetDate = new Date(singleCouponDate);

      return maxDate > targetDate && minDate <= targetDate;
    });

    const totalCouponCount = filterDates.length + (restCount - filterDates.length) * regionalCommonCouponCount;

    // 交通費総額
    const totalTransportationCosts = Number((bus ?? 0)) + Number((car ?? 0)) +
      Number((jal ?? 0)) + Number((jr ?? 0)) + Number((jrEx ?? 0));

    // 交通費総額
    const filterTransportationCosts = Number((jal ?? 0)) + Number((jr ?? 0)) + Number((jrEx ?? 0));

    // 助成額下限
    const lowerLimitOfSubsidyAmount = 2000;

    const discountAmountWithTransportation = 8000;

    const discountAmountWithoutTransportation = 5000;

    // 助成額上限
    const maximumAmountOfSubsidy = value > 0 && filterTransportationCosts > 0 ?
      discountAmountWithTransportation : discountAmountWithoutTransportation;

    // 助成対象下限
    const lowerLimitOfSubsidyTarget = lowerLimitOfSubsidyAmount / discountRate;

    // 助成対象上限
    const maximumAmountOfSubsidyTarget = maximumAmountOfSubsidy / discountRate;

    // 費用総額
    const totalValue = value + totalTransportationCosts;

    // 宿泊単価
    const valueOfDay = totalValue / (restCount * peopleCount);

    if (valueOfDay < lowerLimitOfSubsidyTarget) {

      setValue("commonCoupon", 0);
      setValue("saleRestValue", 0);
      setValue("subsequentRefundAmountValue", 0);
      setValue("saleValue", 0);
      return;
    }

    // 地域限定クーポン単価 * 地域限定クーポン枚数 * 人数 * 泊数
    const commonCouponValue = regionalCommonCouponUnitPrice * totalCouponCount * peopleCount;

    setValue("commonCoupon", commonCouponValue.toLocaleString());

    // 総額単価
    const totalValueOfDay = totalValue / (restCount * peopleCount);

    // 人泊(日)の助成対象額
    const amountOfSubsidyForPeopleStayingByDay = maximumAmountOfSubsidyTarget > totalValueOfDay ?
      totalValueOfDay : maximumAmountOfSubsidyTarget;

    // 割引額
    let discountValue = amountOfSubsidyForPeopleStayingByDay * peopleCount * restCount * discountRate;

    const maximumAmountOfSubsidyForPeopleStaying = maximumAmountOfSubsidyTarget * peopleCount * restCount;

    const discountAmountWithoutTransportationValue = discountAmountWithoutTransportation * peopleCount * restCount;

    // 助成額上限より多い場合は、上限額に再設定する
    if (maximumAmountOfSubsidyTarget < totalValueOfDay) {

      discountValue = maximumAmountOfSubsidyForPeopleStaying * discountRate;

      let saleValueRate = value * discountRate;

      if (saleValueRate > discountValue) {

        saleValueRate = discountValue;
      }

      saleValueRate = saleValueRate > discountAmountWithoutTransportationValue ? discountAmountWithoutTransportationValue : saleValueRate;

      setValue("saleValue", Math.floor(discountValue).toLocaleString());

      setValue("saleRestValue", Math.floor(saleValueRate).toLocaleString());

      setValue("subsequentRefundAmountValue", discountValue - saleValueRate);

    } else {
      setValue("saleValue", Math.floor(discountValue).toLocaleString());

      let saleRestValue = value * discountRate;
      console.log("test", saleRestValue);

      saleRestValue = saleRestValue > discountAmountWithoutTransportationValue ? discountAmountWithoutTransportationValue : saleRestValue;

      setValue("saleRestValue", Math.floor(saleRestValue).toLocaleString());

      const subsequentRefundAmountValue = discountValue - saleRestValue;

      setValue("subsequentRefundAmountValue", Math.floor(subsequentRefundAmountValue).toLocaleString());
    }
  };

  return (
    <Layout>
      <Helmet>
        <html lang="ja" />
        <title>全国旅行支援 割引シミュレーション</title>
        <meta name="description" content="ご予約前に大まかな割引金額と配布される地域限定クーポンの枚数のご確認ができます。" />
      </Helmet>
      <div tw="flex w-full bg-electric items-center justify-center">
        <img src={Logo} alt="StayNavi" />
      </div>
      <div css={styles.container({ hasBackground: true })}>
        <div tw="flex w-full flex-col items-center justify-center rounded-lg border border-gray-200 p-8">
          <div tw="text-center">
            <h1 tw="text-4xl">全国旅行支援 割引シミュレーション</h1>
            <p tw="mx-auto mt-4 lg:w-full text-gray-600">ご予約前に割引金額を計算してみましょう。旅行の割引金額や地域限定クーポンの予定金額が確認できます。</p>
            <h1 tw="mt-4 text-4xl">宿泊予約料金(1施設分のみ)をご入力ください。</h1>
            <p tw="mx-auto mt-4 lg:w-full text-gray-600">※1施設ごと、1グループの旅行の総額をもとに計算します。</p>

            <div tw="p-8 m-8 rounded border bg-blue-50 border-background">
              <form noValidate>
                <h1 tw="font-medium text-lg">宿泊詳細</h1>
                <div tw="mt-8 grid lg:grid-cols-3 gap-4">
                  <label
                    for="value"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">宿泊代金総額</p>
                    <input
                      type="text"
                      id="value"
                      {...register("value", { required: true })}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('value') ?? '');
                        if (isValid) {
                          setValue('value', Number(getValues('value')).toLocaleString());
                        }

                        if (!getValues('value')) {
                          setValue('value', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('value') ?? '');

                        if (isValid) {
                          setValue('value', getValues('value')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="宿泊代金総額を入力してください"
                      tw="w-full bg-transparent border-transparent text-right p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.value && errors.value.type === "required" ? (
                      <p tw="mt-2 text-red-500">宿泊代金総額を入力してください。</p>
                    ) : errors.value ?
                      <p tw="mt-2 text-red-500">{errors.value.message}</p> : null
                    }
                  </label>
                  <label
                    for="peopleCount"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">人数</p>
                    <input
                      type="number"
                      id="peopleCount"
                      {...register("peopleCount", { required: true })}
                      placeholder="人数を入力してください"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.peopleCount && errors.peopleCount.type === "required" && (
                      <p tw="mt-2 text-red-500">人数を入力してください。</p>
                    )}
                  </label>
                </div>

                <div tw="mt-8 grid lg:grid-cols-3 gap-4">

                  <label
                    for="checkIn"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">チェックイン日</p>
                    <input
                      type="date"
                      id="checkIn"
                      {...register("checkIn", { required: true })}
                      placeholder="チェックインする日付を入力してください"
                      tw="w-full bg-transparent border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.checkIn && errors.checkIn.type === "required" ? (
                      <p tw="mt-2 text-red-500">チェックイン日を入力してください。</p>
                    ) : errors.checkIn ?
                      <p tw="mt-2 text-red-500">{errors.checkIn.message}</p> : null
                    }
                  </label>

                  <h1 tw="mt-8 font-medium text-lg">から</h1>

                  <label
                    for="checkOut"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">チェックアウト日</p>
                    <input
                      type="date"
                      id="checkOut"
                      {...register("checkOut", { required: true })}
                      placeholder="チェックアウトする日付を入力してください"
                      tw="w-full bg-transparent border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.checkOut && errors.checkOut.type === "required" ? (
                      <p tw="mt-2 text-red-500">チェックアウト日を入力してください。</p>
                    ) : errors.checkOut ?
                      <p tw="mt-2 text-red-500">{errors.checkOut.message}</p> : null
                    }
                  </label>
                </div>

                <h1 tw="mt-8 font-medium text-lg">STAYNAVI 全国旅行支援組み合わせ割</h1>

                <label for="value" tw="mt-8 mb-2 text-lg text-left text-gray-700 block lg:w-full font-medium">
                オプション（交通付きの割引対象ではありません。宿泊のみの上限に準じます。）
                </label>

                <div tw="grid lg:grid-cols-3 gap-4">
                  <label
                    for="bus"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">高速道路周遊パス</p>
                    <input
                      type="text"
                      id="bus"
                      {...register("bus", {})}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('bus') ?? '');
                        if (isValid) {
                          setValue('bus', Number(getValues('bus')).toLocaleString());
                        }

                        if (!getValues('bus')) {
                          setValue('bus', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('bus') ?? '');

                        if (isValid) {
                          setValue('bus', getValues('bus')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="高速道路周遊パスをご利用の方は費用を入力してください"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.bus ?
                      <p tw="mt-2 text-red-500">{errors.bus.message}</p> : null
                    }
                  </label>

                  <label
                    for="car"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">レンタカー</p>
                    <input
                      type="text"
                      id="car"
                      {...register("car", {})}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('car') ?? '');
                        if (isValid) {
                          setValue('car', Number(getValues('car')).toLocaleString());
                        }

                        if (!getValues('car')) {
                          setValue('car', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('car') ?? '');

                        if (isValid) {
                          setValue('car', getValues('car')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="レンタカーをご利用の方は費用を入力してください"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.car ?
                      <p tw="mt-2 text-red-500">{errors.car.message}</p> : null
                    }
                  </label>
                </div>


                <label for="value" tw="mt-8 mb-2 text-lg text-left text-gray-700 block lg:w-full font-medium">
                  交通付き
                </label>

                <div tw="grid lg:grid-cols-3 gap-4">
                  <label
                    for="jal"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">JAL 国内線航空券</p>
                    <input
                      type="text"
                      id="jal"
                      {...register("jal", {})}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('jal') ?? '');
                        if (isValid) {
                          setValue('jal', Number(getValues('jal')).toLocaleString());
                        }

                        if (!getValues('jal')) {
                          setValue('jal', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('jal') ?? '');

                        if (isValid) {
                          setValue('jal', getValues('jal')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="JAL 国内線をご利用の方は費用を入力してください"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.jal ?
                      <p tw="mt-2 text-red-500">{errors.jal.message}</p> : null
                    }
                  </label>

                  <label
                    for="jr"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">JR 西日本フリーきっぷ</p>
                    <input
                      type="text"
                      id="jr"
                      {...register("jr", {})}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('jr') ?? '');
                        if (isValid) {
                          setValue('jr', Number(getValues('jr')).toLocaleString());
                        }

                        if (!getValues('jr')) {
                          setValue('jr', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('jr') ?? '');

                        if (isValid) {
                          setValue('jr', getValues('jr')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="JR 西日本をご利用の方は費用を入力してください"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.jr ?
                      <p tw="mt-2 text-red-500">{errors.jr.message}</p> : null
                    }
                  </label>


                  <label
                    for="jrEx"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">JR EX・スマート EX</p>
                    <input
                      type="text"
                      id="jrEx"
                      {...register("jrEx", {})}
                      onBlur={() => {
                        const isValid = /^[-]?(\d+)[.]?(\d+)?$/.test(getValues('jrEx') ?? '');
                        if (isValid) {
                          setValue('jrEx', Number(getValues('jrEx')).toLocaleString());
                        }

                        if (!getValues('jrEx')) {
                          setValue('jrEx', '0');
                        }
                      }}
                      onFocus={() => {
                        const isValid = /\b\d{1,3}(,\d{3})*\b/.test(getValues('jrEx') ?? '');

                        if (isValid) {
                          setValue('jrEx', getValues('jrEx')?.replace(/,/g, ''));
                        }
                      }}
                      placeholder="JR EX・スマート EXをご利用の方は費用を入力してください。"
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md focus:outline-none text-gray-700"
                    />
                    {errors.jrEx ?
                      <p tw="mt-2 text-red-500">{errors.jrEx.message}</p> : null
                    }
                  </label>
                </div>


                <div tw="flex w-full flex-col items-center justify-center">
                  <button type={"button"} onClick={handleSubmit(onSubmit)} tw="mt-8 block rounded-lg border border-green-700 bg-blue-800 py-1.5 px-4 font-medium text-white transition-colors hover:bg-blue-700 active:bg-blue-800 disabled:opacity-50">
                  割引金額を計算する
                  </button>
                </div>
                <h1 tw="mt-8 font-medium text-lg">※計算された金額は目安としてお考え下さい。割引額を確定するものではありません。</h1>

                <h1 tw="mt-8 font-medium text-lg">割引金額</h1>

                <div tw="mt-8 grid lg:grid-cols-3 gap-4">

                  <label
                    for="saleValue"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">割引総額</p>
                    <input
                      type="text"
                      id="saleValue"
                      {...register("saleValue")}
                      disabled
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md block focus:outline-none text-gray-700"
                    />
                  </label>

                  <label
                    for="saleRestValue"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">宿泊代金割引額</p>
                    <input
                      type="text"
                      id="saleRestValue"
                      {...register("saleRestValue")}
                      disabled
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md block focus:outline-none text-gray-700"
                    />
                  </label>

                  <label
                    for="subsequentRefundAmountValue"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">事後還付額</p>
                    <input
                      type="text"
                      id="subsequentRefundAmountValue"
                      {...register("subsequentRefundAmountValue")}
                      disabled
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md block focus:outline-none text-gray-700"
                    />
                  </label>
                </div>

                <h1 tw="mt-8 font-medium text-lg">地域限定クーポン</h1>


                <div tw="mt-8 grid lg:grid-cols-3 gap-4">
                  <div>
                  </div>
                  <label
                    for="commonCoupon"
                    tw="p-4 text-gray-800 relative focus-within:text-marron  transition-colors rounded-md block border-2 border-blue-300 focus-within:border-marron"
                  >
                    <p tw="mb-2">地域限定クーポン金額</p>
                    <input
                      type="text"
                      id="commonCoupon"
                      {...register("commonCoupon")}
                      disabled
                      tw="w-full bg-transparent text-right border-transparent p-4 bg-white border-2 rounded-md block focus:outline-none text-gray-700"
                    />
                  </label>
                </div>
              </form>

              <label for="value" tw="text-sm text-left text-red-600 block mt-4 lg:w-full font-medium">入力に関する注意</label>

              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・入力する金額は総額(ご旅行者様全員の、予約した内容の金額)です。
              </label>

              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・子供や幼児も 1 名としてカウントします。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・支援の対象は 7 泊までです。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・宿泊施設は 1 施設ごとに計算してください。(1 施設の連泊ならまとめて、2 施設利用ならそれぞれ分けて計算します)
              </label>

              <label for="value" tw="text-sm text-left text-red-600 block mt-4 lg:w-full font-medium">支援(給付金・地域限定クーポン)に関する注意</label>

              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・割引（または一部還付）金額は、旅行総額の４０％です。
              </label>

              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・宿泊商品は、おひとり様1泊あたり5,000円を上限とします。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・交通付きは、おひとり様1泊あたり8,000円を上限とします。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・地域限定クーポンは平日3,000円、休日1,000円が宿泊施設でチェックイン日に配付されます。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・割引総額とは、割引額と還付額を併せた金額です。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・割引額は、現地支払いのときに割り引かれる金額です。
              </label> 
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・還付額は、予約時に全額支払い後、ご旅行後に申請のうえ返金される金額です。
              </label>            
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・還付は、ご旅行後申請から1か月～2か月程度かかる場合があります。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・還付は、CASHPOSTを通じて返金されます。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
              ・交通付き割引を適用する場合、STAYNAVIサービス利用料がかかります。還付の際サービス利用料を差し引かせていただきます。
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                ・地域限定クーポンは
              </label>
              <label for="value" tw="text-sm text-left text-gray-700 block mt-2 lg:w-full font-medium">
                1.土曜日、 2.翌日が祝日の日曜日、 3.翌日が「土曜・日曜・祝日」の祝日は 1 人 1 泊あたり 1,000 円  それ以外の日は、1 人 1 泊あたり 3,000 円が付与されます。  ただし、旅行割引適用前の旅行金額が 4,500 円以上 6,000 円未満の時は 2,000 円、3,000 円以上 4,500 円未満の時は 1,000 円とし、3,000 円未満の時は付与されません。
              </label>
              <label for="value" tw="text-sm text-left text-red-600 mt-2 lg:w-full font-medium">
                ※宿泊施設1泊の宿泊代金と高速道路周遊パスの金額を足して人数で割った金額が、おひとり様1泊あたり23,333円を超えている場合、高速道路周遊パスは割引適用になりません。
              </label>

            </div>
          </div>
        </div>

      </div >
    </Layout >)
};

export default IndexPage
